import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Container from "../components/container"
import Pdf from "../components/documents/pdf"
import Seo from "../components/seo"

// Document
import termsPdf from "../content/documents/tetra-terms-and-conditions.pdf"

// Styles
import { colors } from "../assets"

const TermsOfUsePage = ({ data }) => {
  const { termsOfUse } = data.markdownRemark
    
  return (
    <Layout>
      <Container background={colors.TETRA_BLACK}>
        <Seo
          title={termsOfUse.seoTitle}
          description={termsOfUse.seoDescription}
          image={termsOfUse.seoImage.publicURL}
        />

        <Pdf
          title={termsOfUse.title}
          doc={termsPdf}
        />
      </Container>
    </Layout>
  )
}

export default TermsOfUsePage

export const query = graphql`
  query {
    markdownRemark(frontmatter: { path: { eq: "/terms-of-use" } }) {
      termsOfUse: frontmatter {
        seoTitle
        seoDescription
        seoImage {
          publicURL
        }
        title
      }
    }
  }
`
